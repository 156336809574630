
import { defineComponent, reactive } from 'vue'
import useServiceVat from "@/hooks/SpotCheckingConfiguration/useServiceVat";
const tableWidth = window.innerWidth
const tableHeight = window.innerHeight-310
import TableWrapper from "@/components/TableWrapper.vue";
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import clickOutside from '@/views/Planning/directive/clickOutside'
import YearPicker from '@/views/Payment/components/YearPicker.vue'
import EditableView from '@/views/Payment/components/EditableView.vue'

export default defineComponent({
  name: 'payoutPreparation',
  components: {
    TableWrapper,
    YearPicker,
    EditableView
  },
  directives: {
    clickOutside
  },
  props: {},
  setup() {
    const {
      year,
      basicColData,
      formRef,
      form,
      saveCell,
      saveClick,
      yearChange
    } = useServiceVat()

    const rules = reactive({
      serviceVat: [
        {
          required: true,
          message: "Please input Service VAT",
          trigger: "change",
        },
        {
          pattern: /^(([1-9]\d*)|0)(\.\d{1,2})?$/,
          message: "Service VAT format error",
          trigger: "change",
        },
      ]
    });
    
    return {
      tableWidth,
      tableHeight,
      year,
      basicColData,
      formRef,
      form,
      rules,
      saveCell,
      yearChange,
      // editCell,
      saveClick,
      toThousandsAndTwoDecimal
    }
  }
})
